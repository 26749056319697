import React, { useEffect } from 'react';
import './scss/docebo/ui/ui-style.scss';
import './App.scss';
import { app } from '@microsoft/teams-js';
import Routes from '../routes/Routes';
import { FluentProvider, Spinner, Theme } from '@fluentui/react-components';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { getShowBreadcrumb } from 'store/breadcrumb/breadcrumb.selectors';
import AteBreadcrumb from 'components/shared/breadcrumb/AteBreadcrumb';
import { updateMsContext } from 'store/msContext/msContext.slice';
import { setMsTeamsTheme, updateFlowSdkStatus } from 'store/app/app.slice';
import { getMsTeamsThemeSelected } from 'store/app/app.selectors';
import { lastValueFrom, Observable, of } from 'rxjs';
import { getBaseUrl, getDcbAccessToken, getDcbAccessTokenCreationAt } from 'store/auth/auth.selectors';
import { isAccessTokenValid, refreshDoceboLearnToken } from 'api/HttpClient';
import { DCB_FLOW_SDK_ID, FLOW_SDK_ATE_CONTEXT, FLOW_SDK_KERNEL_URL, IDSDK } from 'interfaces/DSDK/DSDK.interface';
import { getAppCurrentLanguage, getIsTranslationsFlowCompleted, getTranslations } from 'store/translations/translations.selector';
import { initTranslations } from 'store/translations/translations.slice';
import { AteLocalize } from 'services/translations/AteLocalize';
import { customTeamsDarkTheme, customTeamsHighContrastTheme, customTeamsLightTheme } from './themes';
import classNames from 'classnames';

export enum MSTeamsThemes {
    DEFAULT = 'default',
    DARK = 'dark',
    CONTRAST = 'contrast',
}

export default function App() {
    const dispatch = useDispatch();
    const isVisibleBreadcrumb = useSelector(getShowBreadcrumb);
    const themeSelected = useSelector(getMsTeamsThemeSelected);
    const domain = useSelector(getBaseUrl);
    const accessToken = useSelector(getDcbAccessToken);
    const accessTokenCreationAt = useSelector(getDcbAccessTokenCreationAt);
    const appCurrentLanguage = useSelector(getAppCurrentLanguage);
    const translations = useSelector(getTranslations);
    const isAppReady = useSelector(getIsTranslationsFlowCompleted);

    const mainContainerClasses = classNames({
        'main-container': true,
        'breadcrumb-visible': isVisibleBreadcrumb
    })

    useEffect(() => {
        app.initialize()
            .then(async () => {
                const context = await app.getContext();
                if (context) {
                    dispatch(updateMsContext(context));
                    dispatch(setMsTeamsTheme((context.app.theme as MSTeamsThemes) ?? MSTeamsThemes.DEFAULT));
                    dispatch(initTranslations(context.app.locale));
                }

                app.registerOnThemeChangeHandler((theme: MSTeamsThemes | string) => {
                    dispatch(setMsTeamsTheme(theme));
                });
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (translations && appCurrentLanguage) {
            AteLocalize.setLanguageInUse(appCurrentLanguage);
            AteLocalize.setTranslations(translations);
        }
    }, [translations, appCurrentLanguage])

    useEffect(() => {
        if (domain && accessToken && accessTokenCreationAt && appCurrentLanguage) {
            injectDFlowSDK().subscribe(() => startDSDK());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [domain, accessToken, accessTokenCreationAt, appCurrentLanguage])

    const getMsTeamsTheme = (): Partial<Theme> => {
        let selectedTheme: Partial<Theme>;
        switch (themeSelected) {
            case MSTeamsThemes.CONTRAST:
                selectedTheme = customTeamsHighContrastTheme;
                break;
            case MSTeamsThemes.DARK:
                selectedTheme = customTeamsDarkTheme;
                break;
            default:
                selectedTheme = customTeamsLightTheme;
                break;
        }

        return selectedTheme;
    }

    const injectDFlowSDK = (): Observable<void> => {
        return new Observable<void>(observer => {
            if (document.getElementById(DCB_FLOW_SDK_ID)) {
                observer.next();
                return;
            }

            const flowSdkKernelUrl = FLOW_SDK_KERNEL_URL;

            if (flowSdkKernelUrl) {
                const kernel = document.createElement('script');
                kernel.src = flowSdkKernelUrl;
                kernel.id = DCB_FLOW_SDK_ID;
                kernel.onload = () => observer.next();
                document.body.appendChild(kernel);
            }
        })
    }

    const startDSDK = (): void => {
        const DSDK: IDSDK = (window as any).DSDK;

        DSDK.kernel().setTokenProvider(() =>
            accessTokenCreationAt && isAccessTokenValid(accessTokenCreationAt) ?
                lastValueFrom(of(accessToken)) :
                lastValueFrom(refreshDoceboLearnToken())
        );

        DSDK.kernel().start({
            domain,
            token: accessToken,
            context: FLOW_SDK_ATE_CONTEXT,
            language: appCurrentLanguage
        }).then(() => dispatch(updateFlowSdkStatus(true)));
    }

    return (
        <>
            {
                isAppReady ?
                    <FluentProvider
                        theme={getMsTeamsTheme()}>
                        <BrowserRouter>
                            <div className={`flow-ms-teams ${themeSelected}`}>
                                <AteBreadcrumb />
                                <div
                                    className={mainContainerClasses}
                                >
                                    <Routes />
                                </div>
                            </div>
                        </BrowserRouter>
                    </FluentProvider> :
                    <Spinner />
            }
        </>
    );
}
