import { StyledText, Flex } from '@fluentui/react-migration-v0-v9';
import {
    Spinner,
    Dialog,
    Button,
    DialogTrigger,
    DialogSurface,
    DialogBody,
    DialogTitle, DialogContent, DialogActions
} from '@fluentui/react-components';
import './AteStaticCourseListUngroupedView.scss';
import { getWidgetTranslatedLabel } from 'helpers/translations/translations.helpers';
import { useSelector } from 'react-redux';
import { getUserId } from 'store/session/session.selector';
import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { enrollmentUserInCourse, getCourse } from 'api/BackendCalls';
import { getDcbAccessToken, getBaseUrl } from 'store/auth/auth.selectors';
import { forkJoin, of } from 'rxjs';
import { getBootstrapStore, getSessionStore } from 'store/helpers';
import { courseItemToCard, isCourseAccessible } from 'helpers/course.helpers';
import { LGZone, XLZone, XSZone } from 'helpers/viewportBreakpoint.helpers';
import { useWindowSize } from 'helpers/commons.helpers';
import AteCard from 'components/shared/cards/card/AteCard';
import AteCardListItem from 'components/shared/cards/cardListItem/AteCardListItem';
import { getCardMenu } from 'components/shared/cards/helpers/card.helpers';
import { ICourseInfo, CourseTypeEnum } from 'interfaces/course.interface';
import { getIsCourseSharingEnabled } from 'store/app/app.selectors';
import { useHistory } from 'react-router-dom';
import { PAGES_INFO, PageInfoEnum } from 'routes/pageSettings';
import { AteBlankSlate } from 'components/shared/blankSlate/AteBlankSlate';
import { WidgetTranslationTypeEnum } from 'interfaces/commons.interface';
import _ from 'lodash';
import { catchError, concatMap, map } from 'rxjs/operators';
import { FROM_SCL_PAGE_QUERY_PARAM, IAteStaticCourseListWidget, SCLCourseViewOptions } from '../interfaces/staticCourseList.interface';
import { openCourseSharingDialog } from 'helpers/sharing.helpers';
import { AteLocalize } from 'services/translations/AteLocalize';
import { DICTIONARY } from 'dictionary';
import { getAppCurrentLanguage } from 'store/translations/translations.selector';
import { getDcbLmsDefaultLanguage } from 'store/bootstrap/boostrap.selector';

export default function AteStaticCourseListUngroupedView(props: IAteStaticCourseListWidget) {
    const history = useHistory();

    const accessToken = useSelector(getDcbAccessToken);
    const baseUrl = useSelector(getBaseUrl);
    const userId = useSelector(getUserId);
    const isCourseSharingEnabled = useSelector(getIsCourseSharingEnabled);
    const bootstrap = useSelector(getBootstrapStore);
    const session = useSelector(getSessionStore);
    const dcbLmsDefaultPlatformLanguage = useSelector(getDcbLmsDefaultLanguage);
    const userLanguage = useSelector(getAppCurrentLanguage);

    const isXSZone = XSZone();
    const isLGZone = LGZone();
    const isXLZone = XLZone();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [cardsPerRow, setCardsPerRow] = useState(1);
    const [courses, setCourses] = useState<(ICourseInfo | null)[]>([]);
    const [courseInfoWaitingDialog, setCourseInfoWaitingDialog] = useState<ICourseInfo>();
    const [isWaitingDialogOpened, setIsWaitingDialogOpened] = useState<boolean>(false);

    const databrowserContentRef = useCallback((node) => {
        if (node) {
            calculateCardWidth(node);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [useWindowSize()]);

    useEffect(() => {
        if (props.course_ids.length) {
            setIsLoading(true);
            getCourses();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.course_ids])

    const getCourses = () => {
        const coursesObs = props.course_ids.map(id => getCourse({ accessToken, baseUrl }, id).pipe(catchError(() => of(null))));

        forkJoin(coursesObs)
            .pipe(map(courses => courses.filter(course => !!course && isCourseAccessible(course))))
            .subscribe(courses => {
                setCourses(courses);
                setIsLoading(false);
            });
    }

    const navigateToCoursePlayer = (courseInfo: ICourseInfo) => {
        history.push({
            pathname: PAGES_INFO[PageInfoEnum.COURSE_PLAYER].path,
            state: { id: courseInfo.id },
            search: `?${FROM_SCL_PAGE_QUERY_PARAM}=${props.usedInSCLPage}`
        });
    }

    const calculateCardWidth = (cardContainer: HTMLElement) => {
        const margin = isLGZone || isXLZone ? 24 : 16;
        const cardMinWidth = 200;
        const cardContainerWidth = cardContainer.clientWidth;
        let maxCardPerRow = Math.floor((cardContainerWidth + margin) / (cardMinWidth + margin));

        if (isXSZone) {
            maxCardPerRow = 2;
        }

        setCardsPerRow(maxCardPerRow);
    };

    const onClickCourseItem = (course: ICourseInfo) => {
        if (course.enrolled.waiting_list) {
            return;
        }

        if (course.enrolled.is_enrolled) {
            navigateToCoursePlayer(course)
        } else if (course.can_self_enroll) {
            enrollmentUserInCourse({ accessToken, baseUrl }, course.id, userId)
                .pipe(
                    concatMap(() => getCourse({ accessToken, baseUrl }, course.id))
                )
                .subscribe(courseInfo => {
                    if (courseInfo.enrolled.waiting_list) {
                        const coursesClone = _.cloneDeep(courses);
                        const index = courses.findIndex(course => course?.id === courseInfo.id);
                        coursesClone[index] = courseInfo;
                        setCourses(coursesClone);
                        setCourseInfoWaitingDialog(courseInfo);
                        setIsWaitingDialogOpened(true)
                    } else {
                        navigateToCoursePlayer(course);
                    }
                })
        }
    }

    const getCards = (item: ICourseInfo | null, index: number) => {
        if (!item) {
            return;
        }

        const footerMenuItems = [];

        if (item.type === CourseTypeEnum.E_LEARNING && isCourseSharingEnabled) {
            footerMenuItems.push(
                {
                    key: 'share',
                    content: AteLocalize.getLocalizedLabel(DICTIONARY.shareCourse),
                    onClick: (e: SyntheticEvent<HTMLElement, Event>) => shareCourse(item, e),
                },
            )
        }

        if (props.courses_view === SCLCourseViewOptions.LIST) {
            return (
                <div
                    key={index}
                    className={'card-list ui-col-24'}
                >
                    <AteCardListItem
                        {...courseItemToCard(item, props.courses_view, bootstrap, session, baseUrl)}
                        footerMenu={getCardMenu(footerMenuItems)}
                        onClick={() => onClickCourseItem(item)}
                    />
                </div>
            );
        } else {
            return (
                <div
                    key={index}
                    className={`card-container`}
                >
                    <AteCard
                        {...courseItemToCard(item, props.courses_view, bootstrap, session, baseUrl)}
                        footerMenu={getCardMenu(footerMenuItems)}
                        onClick={() => onClickCourseItem(item)}
                    />
                </div >
            );
        }
    };

    const shareCourse = (item: ICourseInfo, event: React.SyntheticEvent<HTMLElement, Event>) => {
        event.preventDefault();
        event.stopPropagation();
        openCourseSharingDialog({ id: item.id, name: item.name })
    }

    return (
        <>
            <Flex
                data-testid="ate-static-course-list-ungrouped-view"
                className="ate-static-course-list-ungrouped-view"
                column>
                {
                    props.title &&
                    <Flex
                        className="title-container">
                        <StyledText
                            size={'large'}
                            weight={'bold'}
                        >
                            {getWidgetTranslatedLabel(props.title, userLanguage, dcbLmsDefaultPlatformLanguage)}
                        </StyledText>
                    </Flex>
                }
                {
                    !props.usedInSCLPage &&
                    props.subtitle &&
                    <Flex
                        className="description-container">
                        <StyledText
                            size={'medium'}
                        >
                            {getWidgetTranslatedLabel(props.subtitle, userLanguage, dcbLmsDefaultPlatformLanguage)}
                        </StyledText>
                    </Flex>
                }
                <div ref={databrowserContentRef}>
                    {
                        isLoading ?
                            <div className="spinner">
                                <Spinner />
                            </div>
                            :
                            courses.length ?
                                <div
                                    className={`content ${props.courses_view === SCLCourseViewOptions.LIST ? 'cards-list-container' : `cards-container-grid-${cardsPerRow}`}`}>
                                    {courses.map((course, index) => getCards(course, index))}
                                </div>
                                :
                                <Flex
                                    data-testid="blankslate"
                                    hAlign="center"
                                    vAlign="center">
                                    <AteBlankSlate
                                        title={{
                                            type: WidgetTranslationTypeEnum.SINGLE,
                                            value: AteLocalize.getLocalizedLabel(DICTIONARY.blankSlateWhoopsThereSNothingHere),
                                        }}
                                        subtitle={{
                                            type: WidgetTranslationTypeEnum.SINGLE,
                                            value: AteLocalize.getLocalizedLabel(DICTIONARY.blankSlateLooksLikeThereSNothingForYouToDoInThisAreaRightNow),
                                        }}
                                        illustrationName='take-a-break'
                                    />
                                </Flex>
                    }
                </div>
            </Flex >
            {
                isWaitingDialogOpened &&
                <Dialog
                    modalType="alert"
                    open={isWaitingDialogOpened}>
                    <DialogSurface>
                        <DialogBody>
                            <DialogTitle>{AteLocalize.getLocalizedLabel(DICTIONARY.waitingForApprovalDialog)}</DialogTitle>
                            <DialogContent>{AteLocalize.getLocalizedLabel(courseInfoWaitingDialog ? DICTIONARY.waitingForApprovalDialogSubtitle(courseInfoWaitingDialog.name) : '')}</DialogContent>
                            <DialogActions>
                                <DialogTrigger>
                                    <Button appearance="secondary" onClick={() => setIsWaitingDialogOpened(false)}>{AteLocalize.getLocalizedLabel(DICTIONARY.okGotIt)}</Button>
                                </DialogTrigger>
                            </DialogActions>
                        </DialogBody>
                    </DialogSurface>
                </Dialog>
            }
        </>
    )
}
