import { AuthErrorEnum } from "interfaces/Auth";
import { RootState } from "../reducers";

export const getIsLogged = (state: RootState): boolean => state.auth.isLogged;
export const getDcbAccessToken = (state: RootState): string => state.auth.dcbAccessToken.token;
export const getDcbAccessTokenCreationAt = (state: RootState): number | undefined => state.auth.dcbAccessToken.createdAt;
export const getBaseUrl = (state: RootState): string => state.auth.baseUrl;
export const getUserNeedToAcceptPolicy = (state: RootState): boolean => state.auth.userNeedToAcceptPolicy;
export const getUserNeedToAcceptTermsAndConditions = (state: RootState): boolean => state.auth.userNeedToAcceptTermsAndConditions;
export const getAuthenticationError = (state: RootState): AuthErrorEnum | undefined => state.auth.authError;
