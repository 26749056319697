import { StyledText, Flex } from '@fluentui/react-migration-v0-v9';
import { Spinner } from '@fluentui/react-components';
import { getLearningPlan } from 'api/BackendCalls';
import { IAteBreadcrumbItem } from 'components/shared/breadcrumb/interfaces/breadcrumb.interface';
import AteCardListItem from 'components/shared/cards/cardListItem/AteCardListItem';
import { getCardMenu } from 'components/shared/cards/helpers/card.helpers';
import { FROM_MYCL_QUERY_PARAM } from 'components/widgets/myCoursesAndLp/helpers/const';
import { useQuery } from 'helpers/location.helpers';
import { CourseTypeEnum } from 'interfaces/course.interface';
import { ILearningPlan, ILearningPlanCourse } from 'interfaces/learningPlan.interface';
import { SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PAGES_INFO, PageInfoEnum } from 'routes/pageSettings';
import { getIsCourseSharingEnabled } from 'store/app/app.selectors';
import { resetLearningPlanSettings, updateLearningPlanSettings } from 'store/app/app.slice';
import { getDcbAccessToken, getBaseUrl } from 'store/auth/auth.selectors';
import { updateBreadcrumbItems } from 'store/breadcrumb/breadcrumb.slice';
import { getBootstrapStore } from 'store/helpers';
import { lpItemtoCard } from './helpers/learningPlan.helpers';
import './LearningPlanPlage.scss';
import { openCourseSharingDialog } from 'helpers/sharing.helpers';
import { AteLocalize } from 'services/translations/AteLocalize';
import { DICTIONARY } from 'dictionary';

export default function LearningPlanPlage(props: any) {
    const { id } = props.location?.state || props.match?.params;
    const accessToken = useSelector(getDcbAccessToken);
    const baseUrl = useSelector(getBaseUrl);
    const bootstrap = useSelector(getBootstrapStore);
    const isCourseSharingEnabled = useSelector(getIsCourseSharingEnabled);

    const queryParams = useQuery();
    const dispatch = useDispatch();
    const history = useHistory();

    const [learningPlanData, setLearningPlanData] = useState<ILearningPlan>()

    useEffect(() => {
        getLearningPlan({ accessToken, baseUrl }, id)
            .subscribe(setLearningPlanData)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (learningPlanData) {
            dispatch(updateLearningPlanSettings(learningPlanData))
            setBreadcrumb();
        }

        return () => {
            if (learningPlanData) {
                dispatch(resetLearningPlanSettings(undefined));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [learningPlanData])

    const setBreadcrumb = () => {
        const previousHistory: IAteBreadcrumbItem[] = [
            PAGES_INFO[PageInfoEnum.DASHBOARD]
        ];

        if (queryParams.get(FROM_MYCL_QUERY_PARAM) === 'true') {
            previousHistory.push(PAGES_INFO[PageInfoEnum.MY_COURSES_AND_LP])
        }

        dispatch(
            updateBreadcrumbItems({
                items: [
                    ...previousHistory,
                    {
                        path: `/learning-plan/${learningPlanData!.id}`,
                        title: learningPlanData!.name
                    }
                ]
            })
        );
    }

    const onClickCourse = (item: ILearningPlanCourse) => {
        history.push({
            pathname: PAGES_INFO[PageInfoEnum.COURSE_PLAYER].path,
            state: { id: item.idCourse },
            search: `?${FROM_MYCL_QUERY_PARAM}=${queryParams.get(FROM_MYCL_QUERY_PARAM)}`,
        });
    }

    const shareCourse = (item: ILearningPlanCourse, event: React.SyntheticEvent<HTMLElement, Event>) => {
        event.preventDefault();
        event.stopPropagation();
        openCourseSharingDialog({ id: item.idCourse, name: item.name });
    }

    const getCards = (item: ILearningPlanCourse) => {
        const footerMenuItems = [];
        if (item.course_type === CourseTypeEnum.E_LEARNING && isCourseSharingEnabled) {
            footerMenuItems.push(
                {
                    key: 'share',
                    content: AteLocalize.getLocalizedLabel(DICTIONARY.shareCourse),
                    onClick: (e: SyntheticEvent<HTMLElement, Event>) => shareCourse(item, e),
                },
            )
        }

        return (
            <div key={item.idCourse} className={'cards-list-container'}>
                <AteCardListItem
                    {...lpItemtoCard(item, bootstrap, baseUrl)}
                    key={item.idCourse}
                    footerMenu={getCardMenu(footerMenuItems)}
                    onClick={() => onClickCourse(item)}
                />
            </div>
        );
    };

    return (
        <>
            {
                learningPlanData ?
                    <div
                        className="learning-plan-page">
                        <div
                            className="ui-row">
                            <Flex column>
                                <StyledText
                                    weight={'bold'}
                                    size={'large'}
                                    className={'title'}
                                >
                                    {learningPlanData.name}
                                </StyledText>
                            </Flex>
                            <div
                                className="content">
                                {
                                    learningPlanData.courses?.map(course =>
                                        getCards(course)
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    :
                    <div className={'spinner-lp'}>
                        <Spinner />
                    </div>
            }
        </>
    )
}
