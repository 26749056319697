import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { app, FrameContexts, HostClientType, HostName } from '@microsoft/teams-js';

export const msContextInitialState: app.Context = {
    app: {
        locale: '',
        theme: '',
        sessionId: '',
        host: {
            name: HostName.teams,
            clientType: HostClientType.web,
            sessionId: '',
            ringId: 'general'
        },
    },
    page: {
        frameContext: FrameContexts.content,
        id: ''
    },
    dialogParameters: {}
};

const slice = createSlice({
    name: 'msContext',
    initialState: msContextInitialState,
    reducers: {
        updateMsContext: (state, action: PayloadAction<app.Context>) => {
            state = { ...action.payload };
            return state;
        },
    },
});

export const { updateMsContext } = slice.actions;
export default slice.reducer;
