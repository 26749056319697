import {
    Spinner,
    Button,
    Checkbox,
    Dialog,
    DialogSurface,
    DialogBody,
    DialogTitle,
    DialogContent, DialogActions, DialogTrigger
} from '@fluentui/react-components';
import { StyledText } from '@fluentui/react-migration-v0-v9';
import { DismissRegular } from '@fluentui/react-icons';
import { acceptPrivacyPolicy, getPrivacyPolicy } from "api/LMSPolicies";
import { addTargetBlankToLinks, useWindowSize } from "helpers/commons.helpers";
import { ISubPolicie, SubPolicieAnswer } from "interfaces/policies.interface";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBaseUrl, getDcbAccessToken } from "store/auth/auth.selectors";
import { updateUserNeedToAcceptPolicy } from "store/auth/auth.slice";
import { getDcbLmsDefaultLanguage, getPrivacyPolicyId } from "store/bootstrap/boostrap.selector";
import { getSessionStore } from "store/helpers";
import './PrivacyPolicyDialog.scss';
import { AteLocalize } from "services/translations/AteLocalize";
import { DICTIONARY } from "dictionary";
import { getAppCurrentLanguage } from "store/translations/translations.selector";
import { MDZone, LGZone, XLZone } from 'helpers/viewportBreakpoint.helpers';

interface PrivacyPolicyDialogProps {
    isDialogVisible: boolean;
    onDialogVisibilityStatusChanges(isVisible: boolean, isPrivacyPolicyAccepted?: boolean): void;
}

export default function PrivacyPolicyDialog(props: PrivacyPolicyDialogProps) {
    const dispatch = useDispatch();

    const baseUrl = useSelector(getBaseUrl);
    const accessToken = useSelector(getDcbAccessToken);
    const defaultLanguage = useSelector(getDcbLmsDefaultLanguage);
    const userLanguage = useSelector(getAppCurrentLanguage);
    const privacyPolicyId = useSelector(getPrivacyPolicyId);
    const session = useSelector(getSessionStore);
    const isPrivacyPolicyUpdated = session.need_policy_accept?.policy?.is_update;

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [isConfirmButtonLoading, setIsConfirmButtonLoading] = useState<boolean>(false);
    const [areAllMandatoryPoliciesAccepted, setAreAllMandatoryPoliciesAccepted] = useState<boolean>(false);

    const isMDZone = MDZone();
    const isLGZone = LGZone();
    const isXLZone = XLZone();

    const [isMobile, setIsMobile] = useState<boolean>(false);

    //Title Privacy Policy
    const [privacyPolicyTitle, setPrivacyPolicyTitle] = useState<string>();
    //Title Body
    const [ppBodyTitle, setPpBodyTitle] = useState<string>();
    //Content Body
    const [ppBodyMessage, setPpBodyMessage] = useState<string>();
    //Policy Acceptance Message (Checkbox)
    const [privacyPolicyAcceptanceMessage, setPrivacyPolicyAcceptanceMessage] = useState<string>();
    //Policy Acceptance Status
    const [isPrivacyPolicyAccepted, setIsPrivacyPolicyAccepted] = useState<boolean>(false);
    //Other sub policies (checkboxes)
    const [subPolicies, setSubPolicies] = useState<ISubPolicie[]>([]);

    useEffect(() => {
        setIsMobile(!isMDZone && !isLGZone && !isXLZone)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [useWindowSize()])

    useEffect(() => {
        setIsLoading(true);

        if (privacyPolicyId && props.isDialogVisible) {
            getPrivacyPolicy({ baseUrl, accessToken }, privacyPolicyId)
                .subscribe(processPrivacyPolicyResponse)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [privacyPolicyId, props.isDialogVisible])

    useEffect(() => {
        checkConfirmButtonStatus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPrivacyPolicyAccepted, subPolicies])

    const processPrivacyPolicyResponse = (response: any): void => {
        setPpBodyTitle(
            response.policy.policy_header[userLanguage] ||
            response.policy.policy_header[defaultLanguage]
        )

        setPrivacyPolicyAcceptanceMessage(
            response.policy.policy_acceptance_message[userLanguage] ||
            response.policy.policy_acceptance_message[defaultLanguage]
        )

        setPrivacyPolicyTitle(
            response.policy.policy_name[userLanguage] ||
            response.policy.policy_name[defaultLanguage]
        )

        setPpBodyMessage(
            response.policy.policy_body[userLanguage] ||
            response.policy.policy_body[defaultLanguage]
        )

        if (response.sub_policy) {
            const subPoliciesProcessed: ISubPolicie[] = response.sub_policy.map((subPolicy: any) => {
                const acceptanceMessage =
                    subPolicy.sub_policy_acceptance_message[userLanguage] ||
                    subPolicy.sub_policy_acceptance_message[defaultLanguage];

                return {
                    id: subPolicy.id,
                    subPolicyAcceptanceMessage: acceptanceMessage,
                    mandatory: subPolicy.mandatory === 1,
                    translations: subPolicy.sub_policy_acceptance_message,
                    answer: false
                };
            });

            setSubPolicies(subPoliciesProcessed)
        }

        setIsPrivacyPolicyAccepted(false);
        setIsLoading(false);
    }

    const onSubPoliciesStatusChanges = (subPolicie: ISubPolicie) => {
        const policies = _.cloneDeep(subPolicies);
        const subPolicieChanged = policies.find(s => s.id === subPolicie.id);
        if (subPolicieChanged) {
            subPolicieChanged.answer = !subPolicieChanged.answer;
        }
        setSubPolicies(policies)
    }

    const checkConfirmButtonStatus = () => {
        const mandatorySubPolicies = subPolicies.filter(s => s.mandatory);
        const areAllMandatorySubPoliciesAccepted = mandatorySubPolicies.every(s => s.answer);

        setAreAllMandatoryPoliciesAccepted(isPrivacyPolicyAccepted && areAllMandatorySubPoliciesAccepted);
    }

    const sendPrivacyPolicyAcceptance = () => {
        if (!areAllMandatoryPoliciesAccepted) {
            return;
        }

        const subPoliciesAdaptedToPayload: SubPolicieAnswer[] = subPolicies.map(s => {
            return {
                id: s.id,
                answer: s.answer
            }
        });

        setIsConfirmButtonLoading(true);

        acceptPrivacyPolicy({ accessToken, baseUrl }, privacyPolicyId, isPrivacyPolicyAccepted, subPoliciesAdaptedToPayload)
            .subscribe(() => {
                dispatch(updateUserNeedToAcceptPolicy(false));
                setIsConfirmButtonLoading(false);
                props.onDialogVisibilityStatusChanges(false, true);
            })
    }

    return (
        <Dialog
            modalType="alert"
            open={props.isDialogVisible}>
            {!isLoading ?
                <DialogSurface
                    className={isMobile ? 'dialog-mobile' : 'dialog-desktop'}>
                    <DialogBody
                        className={isMobile ? 'dialog-mobile' : 'dialog-desktop'}>
                        <DialogTitle action={
                            <DismissRegular
                                className={'dismiss-button'}
                                onClick={() => props.onDialogVisibilityStatusChanges(false)}
                            />
                        }>
                            <StyledText
                                style={{ display: privacyPolicyTitle && privacyPolicyTitle.length ? 'block' : 'none' }}
                                size={'large'}
                                weight="semibold"
                            >
                                {privacyPolicyTitle}
                            </StyledText>
                            <StyledText
                                weight="light"
                                size="small"
                            >
                                {isPrivacyPolicyUpdated ?
                                    AteLocalize.getLocalizedLabel(DICTIONARY.youMustReadAndAgreeToTheNewPrivacyPolicyInOrderToProceed) :
                                    AteLocalize.getLocalizedLabel(DICTIONARY.youMustReadAndAgreeToThePrivacyPolicyInOrderToProceed)
                                }
                            </StyledText>
                        </DialogTitle>
                        <DialogContent>
                            <p
                                style={{ display: ppBodyTitle && ppBodyTitle.length ? 'block' : 'none' }}
                                dangerouslySetInnerHTML={{ __html: addTargetBlankToLinks(ppBodyTitle!) }}>
                            </p>
                            <p
                                dangerouslySetInnerHTML={{ __html: addTargetBlankToLinks(ppBodyMessage!) }}>
                            </p>
                            <div className={'sub-policies-section'}>
                                <Checkbox
                                    key={0}
                                    label={`${privacyPolicyAcceptanceMessage} ${AteLocalize.getLocalizedLabel(DICTIONARY.required)}`}
                                    checked={isPrivacyPolicyAccepted}
                                    onClick={() => setIsPrivacyPolicyAccepted(!isPrivacyPolicyAccepted)}
                                    labelPosition="after"
                                />
                                {
                                    subPolicies.map((subPolicie, index) => (
                                        <Checkbox
                                            key={index + 1}
                                            label={`${subPolicie.subPolicyAcceptanceMessage} ${subPolicie.mandatory ? AteLocalize.getLocalizedLabel(DICTIONARY.required) : ''}`}
                                            checked={subPolicie.answer}
                                            onClick={() => onSubPoliciesStatusChanges(subPolicie)}
                                            labelPosition="after"
                                        />
                                    ))
                                }
                            </div>
                        </DialogContent>
                        <DialogActions fluid={true}>
                            <DialogTrigger>
                                <Button
                                    onClick={() => props.onDialogVisibilityStatusChanges(false)}
                                    disabled={isConfirmButtonLoading}
                                >
                                    {AteLocalize.getLocalizedLabel(DICTIONARY.iDonTAgree)}
                                </Button>
                            </DialogTrigger>
                            <DialogTrigger>
                                <Button
                                    appearance="primary"
                                    disabled={!areAllMandatoryPoliciesAccepted || isConfirmButtonLoading}
                                    onClick={() => sendPrivacyPolicyAcceptance()}
                                >
                                    {
                                        isConfirmButtonLoading ?
                                            <Spinner size="extra-small" /> :
                                            AteLocalize.getLocalizedLabel(DICTIONARY.iAgree)
                                    }
                                </Button>
                            </DialogTrigger>
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
                : <Spinner />}
        </Dialog>
    )
}
