import { IAteCard } from "components/shared/cards/interfaces/card.interface";
import { DICTIONARY } from "dictionary";
import { stripHTMLFromString } from "helpers/commons.helpers";
import { getContentLanguageCodeFromEndpointLanguage, getCourseBadgeStatusInfo, getDefaultCourseThumbnail } from "helpers/course.helpers";
import { IBootstrap } from "interfaces/Bootstrap";
import { CourseStatusEnum, CourseTypeEnum } from "interfaces/course.interface";
import { ILearningPlanCourse } from "interfaces/learningPlan.interface";
import _ from "lodash";
import { AteLocalize } from "services/translations/AteLocalize";

export const lpItemtoCard = (
    lpItem: ILearningPlanCourse,
    boostrap: IBootstrap,
    baseUrl: string
): IAteCard => {
    return {
        id: lpItem.idCourse,
        title: lpItem.name,
        rightLabel: getCourseBadgeStatusInfo(lpItem.status).label,
        rightLabelColor: getCourseBadgeStatusInfo(lpItem.status).color,
        imageUrl: lpItem.image || getDefaultCourseThumbnail(lpItem.course_type, baseUrl),
        detailsFirstItem: lpItem.lang_code ? getContentLanguageCodeFromEndpointLanguage(lpItem.lang_code, boostrap.active_languages) : '',
        detailsSecondItem: _.capitalize(lpItem.course_type),
        locked: !(lpItem.can_enter && lpItem.status !== CourseStatusEnum.WAITING && lpItem.course_type === CourseTypeEnum.E_LEARNING),
        subtitle: getSubtitle(lpItem),
    };
}

export const getSubtitle = (lpItem: ILearningPlanCourse): string | undefined => {
    switch (lpItem.course_type) {
        case CourseTypeEnum.CLASSROOM:
        case CourseTypeEnum.WEBINAR:
            return AteLocalize.getLocalizedLabel(DICTIONARY.courseCurrentlyNotAvailable);

        case CourseTypeEnum.E_LEARNING:
            return !lpItem.can_enter && lpItem.can_enter_reason ?
                lpItem.can_enter_reason :
                stripHTMLFromString(lpItem.description);
    }
}
