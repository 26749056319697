/**
 * Modifies the lightness of a hexadecimal color by a given percentage.
 *
 * This function directly adjusts the RGB values of a hexadecimal color.
 * Each RGB component is increased or decreased based on the percent parameter,
 * allowing for a simple way to make a color lighter or darker.
 * The function ensures that the RGB values remain within the valid range of 0 to 255.
 *
 * @param {string} hex - The color in hexadecimal format (#RRGGBB).
 * @param {number} percent - The percentage to adjust the color's brightness.
 * Positive to lighten, negative to darken.
 * @returns {string} - The modified color in hexadecimal format.
 */
export const adjustColor = (hex: string, percent: number): string => {
    const change = Math.floor(255 * percent / 100);

    const red = parseInt(hex.slice(1, 3), 16);
    const green = parseInt(hex.slice(3, 5), 16);
    const blue = parseInt(hex.slice(5, 7), 16);

    const r = Math.max(0, Math.min(255, red + change));
    const g = Math.max(0, Math.min(255, green + change));
    const b = Math.max(0, Math.min(255, blue + change));

    const rr = r.toString(16).padStart(2, '0');
    const gg = g.toString(16).padStart(2, '0');
    const bb = b.toString(16).padStart(2, '0');

    return `#${rr}${gg}${bb}`;
}
