import { ICourseStatusInfo } from 'components/widgets/coursePlayer/interface/coursePlayer.interface';
import { CourseEnterStatus, CourseStatusEnum, CourseTypeEnum, ICourseInfo } from 'interfaces/course.interface';
import { ActiveLanguagesType, IBootstrap } from 'interfaces/Bootstrap';
import { AteIconName } from 'components/shared/icon/interfaces/icon.interfaces';
import { IAteCard } from 'components/shared/cards/interfaces/card.interface';
import { getCardSubtitle, getCardDetailsSecondItem, getCardDetailsThirdItems, getCardFooterLabel } from 'components/widgets/myCoursesAndLp/helpers/MyCoursesAndLpItemsToCards.helper';
import { ISession } from 'interfaces/Session';
import { SCLCourseViewOptions } from 'components/widgets/staticCourseList/interfaces/staticCourseList.interface';
import { AteLocalize } from 'services/translations/AteLocalize';
import { DICTIONARY } from 'dictionary';

/**
 * @param type string course type
 * @param baseUrl
 * @return returns default image based on course type
 */
export const getDefaultCourseThumbnail = (type: CourseTypeEnum, baseUrl: string): string => {
    const basePath = `https://${baseUrl}/public/images/placeholders`;

    switch (type) {
        case CourseTypeEnum.E_LEARNING:
            return `${basePath}/elearning.svg`;
        case CourseTypeEnum.CLASSROOM:
            return `${basePath}/classroom.svg`;
        case CourseTypeEnum.LEARNING_PLAN:
            return `${basePath}/learningplan.svg`;
        case CourseTypeEnum.WEBINAR:
            return `${basePath}/webinar.svg`;
        default:
            return '';
    }
};

export const getContentLanguageCodeFromEndpointLanguage = (endpointLanguage: string | undefined, activeLanguages: ActiveLanguagesType | any): string => {
    let languageCode = '';

    if (endpointLanguage) {
        if (endpointLanguage.length === 2) {
            languageCode = endpointLanguage;
        } else {
            if (activeLanguages) {
                for (let key in activeLanguages) {
                    if (activeLanguages.hasOwnProperty(key) && activeLanguages[key].code === endpointLanguage) {
                        languageCode = activeLanguages[key].browsercode;
                    }
                }
            }
        }
    }

    return languageCode.slice(0, 2).toUpperCase();
};

const padDuration = (duration: number): string => {
    return `0${duration}`.slice(-2);
};

export const getFormattedDuration = (duration: number): string => {
    const seconds = duration % 60;
    const minutes = Math.floor(duration / 60) % 60;
    const hours = Math.floor(duration / 3600);

    return hours ?
        `${hours}h ${padDuration(minutes)}m` :
        `${padDuration(minutes)}m ${padDuration(seconds)}s`;
};

export const getCourseBadgeStatusInfo = (courseStatus: CourseStatusEnum): ICourseStatusInfo => {
    switch (courseStatus) {
        case CourseStatusEnum.ENROLLED:
        case CourseStatusEnum.SUBSCRIBED:
        case CourseStatusEnum.NOT_SUBSCRIBED:
        case CourseStatusEnum.NOT_STARTED:
            return {
                color: 'success',
                label: AteLocalize.getLocalizedLabel(DICTIONARY.notStarted),
            };
        case CourseStatusEnum.IN_PROGRESS:
        case CourseStatusEnum.IN_PROGRESS_WITH_SPACE:
        case CourseStatusEnum.IN_PROGRESS_LP:
            return {
                color: 'important',
                label: AteLocalize.getLocalizedLabel(DICTIONARY.inProgress),
            };
        case CourseStatusEnum.WAITING:
        case CourseStatusEnum.CONFIRM_SUBSCRIPTION:
            return {
                color: 'warning',
                label: AteLocalize.getLocalizedLabel(DICTIONARY.waitingList),
            };
        case CourseStatusEnum.COMPLETED:
        case CourseStatusEnum.DONE:
            return {
                color: 'important',
                label: AteLocalize.getLocalizedLabel(DICTIONARY.completed),
            };
        default:
            return {
                color: 'success',
                label: ''
            }
    }
};

export const getCourseTypeLabel = (courseType: CourseTypeEnum): string => {
    switch (courseType) {
        case CourseTypeEnum.E_LEARNING:
            return AteLocalize.getLocalizedLabel(DICTIONARY.eLearning);
        case CourseTypeEnum.LEARNING_PLAN:
            return AteLocalize.getLocalizedLabel(DICTIONARY.learningPlan);
        case CourseTypeEnum.WEBINAR:
            return AteLocalize.getLocalizedLabel(DICTIONARY.webinar);
        case CourseTypeEnum.CLASSROOM:
            return AteLocalize.getLocalizedLabel(DICTIONARY.classroom);
    }
};

export const getCourseIcon = (courseType: CourseTypeEnum): AteIconName => {
    switch (courseType) {
        case CourseTypeEnum.E_LEARNING:
            return 'elearning';
        case CourseTypeEnum.LEARNING_PLAN:
            return 'learningplan';
        case CourseTypeEnum.WEBINAR:
            return 'webinar';
        case CourseTypeEnum.CLASSROOM:
            return 'classroom';
    }
}

export const isCourseAccessible = (course: ICourseInfo): boolean => {
    const isPriced = course.enter_status.status === CourseEnterStatus.BUY && !course.enrolled.is_enrolled;

    if (isPriced) {
        return false;
    }

    return course.type === CourseTypeEnum.E_LEARNING &&
        (course.enrolled.is_enrolled || course.enter_status.status !== CourseEnterStatus.LOCKED)
}

export const courseItemToCard = (
    courseItem: ICourseInfo,
    view: SCLCourseViewOptions,
    bootstrap: IBootstrap,
    session: ISession,
    baseUrl: string
): IAteCard => {
    return {
        id: courseItem.id,
        title: courseItem.name,
        imageUrl: courseItem.thumbnail || getDefaultCourseThumbnail(courseItem.type, baseUrl),
        subtitle: getCardSubtitle(courseItem, bootstrap, session),
        rightLabel: getCourseBadgeStatusInfo(courseItem.status)?.label,
        rightLabelColor: getCourseBadgeStatusInfo(courseItem.status)?.color,
        detailsFirstItem: getContentLanguageCodeFromEndpointLanguage(courseItem.lang_code, bootstrap.active_languages),
        detailsSecondItem: getCardDetailsSecondItem(courseItem, view === SCLCourseViewOptions.CARDS),
        detailsThirdItem: getCardDetailsThirdItems(courseItem),
        footerIcon: getCourseIcon(courseItem.type),
        footerLabel: getCardFooterLabel(courseItem, view === SCLCourseViewOptions.CARDS),
        rating: courseItem.current_rating,
        locked: courseItem.enrolled.is_enrolled && !courseItem.can_enter,
        stacked: courseItem.type === CourseTypeEnum.LEARNING_PLAN
    };
}
