import { Card, Divider, Badge } from '@fluentui/react-components';
import './AteCardListItem.scss';
import { IAteCard } from '../interfaces/card.interface';
import React from 'react';
import { AteIcon } from 'components/shared/icon/AteIcon';
import { IconSizeEnum } from 'components/shared/icon/helpers/icon-size';
import { IconColorEnum } from 'components/shared/icon/helpers/icon-color';
import { StyledText, Flex } from '@fluentui/react-migration-v0-v9';

export default function AteCardListItem(props: IAteCard) {
    const handleOnClick = (): void => {
        if (!props.locked && props.onClick) {
            props.onClick();
        }
    }

    const getBackgroundImageCssRule = () => {
        return { '--course-background-image': `url('${props.imageUrl}')` } as React.CSSProperties;
    };

    return (
        <Card
            className={`ate-card-list-item ${props.locked ? 'locked' : ''}`}
            orientation={'horizontal'}
            onClick={handleOnClick}>
            <Flex vAlign={'center'} className={'list-item-preview-wrapper'}>
                <Flex vAlign={'center'} className={'list-item-preview-container'}>
                    <Flex
                        style={
                            {
                                ...getBackgroundImageCssRule(),
                            }
                        }
                        className={'list-item-image'}
                        vAlign={'end'}
                    />
                    {props.leftLabel && !props.locked &&
                        <Badge appearance="filled"
                               shape="rounded"
                               color={props.leftLabelColor}
                               className={'list-item-left-label'}>
                            <StyledText size={'smaller'}>
                                {props.leftLabel}
                            </StyledText>
                        </Badge>
                    }
                    {props.locked &&
                        <Flex vAlign={'center'}
                            hAlign={'center'}
                            className={'locked'}>
                            <div className="container">
                                <AteIcon
                                    size={IconSizeEnum.MD}
                                    name={'locked'}
                                    color={IconColorEnum.NEGATIVE}
                                />
                            </div>
                        </Flex>
                    }
                    {props.rightLabel && !props.locked &&
                        <Badge  appearance="filled"
                                shape="rounded"
                                color={props.rightLabelColor}
                                className={'list-item-right-label'}>
                            <StyledText size={'smaller'}>
                                {props.rightLabel}
                            </StyledText>
                        </Badge>
                    }
                </Flex>
            </Flex>

            <Flex className={'list-item-body'}
                column>
                <StyledText className={'list-item-title'}
                    size={'medium'}
                    weight={'semibold'} >
                    {props.title}
                </StyledText>
                <Flex column>
                    {!!props.subtitle &&
                        <StyledText className={'list-item-available'}
                            size={'small'}
                            weight={'semilight'} >
                            {props.subtitle}
                        </StyledText>
                    }
                    <Flex>
                        {props.rating ?
                            <Flex
                                className={'list-item-rating'}
                                vAlign={'center'}>
                                <AteIcon
                                    size={IconSizeEnum.XS}
                                    name={'star-filled'}
                                    color={IconColorEnum.SUCCESS}
                                />
                                <StyledText
                                    className={'number'}
                                    size={'small'}
                                    weight={'bold'} >
                                    {props.rating}
                                </StyledText>
                            </Flex> : ''
                        }
                        <Flex
                            vAlign={'center'}>
                            <StyledText
                                size={'small'}
                                weight={'semibold'} >
                                {props.detailsFirstItem}
                            </StyledText>
                            {props.detailsSecondItem &&
                                <>
                                    <Divider className={'list-item-details-divider'}
                                        vertical
                                        color={'grey'} />
                                    <StyledText
                                        size={'small'}
                                        weight={'semibold'} >
                                        {props.detailsSecondItem}
                                    </StyledText>
                                </>
                            }
                            {props.detailsThirdItem &&
                                <>
                                    <Divider className={'list-item-details-divider'}
                                        vertical
                                        color={'grey'} />
                                    <StyledText
                                        size={'small'}
                                        weight={'semibold'} >
                                        {props.detailsThirdItem}
                                    </StyledText>
                                </>
                            }
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
            <Flex className={'list-item-footer'}
                hAlign={'end'}
                vAlign={'start'}>
                {!props.locked && props.footerMenu}
            </Flex>
        </Card>
    );
}

