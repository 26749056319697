import React, { useCallback, useEffect, useState } from 'react';
import { Flex, StyledText } from '@fluentui/react-migration-v0-v9';
import { Spinner } from '@fluentui/react-components';
import './AteMyCoursesAndLp.scss';
import { HttpConfig } from 'api/HttpClient';
import { useDispatch, useSelector } from 'react-redux';
import { getBaseUrl, getDcbAccessToken } from 'store/auth/auth.selectors';
import { getUserId } from 'store/session/session.selector';
import { useHistory } from 'react-router-dom';
import { Subscription } from 'rxjs';
import { CourseStatusEnum, CourseTypeEnum } from 'interfaces/course.interface';
import { getWidgetTranslatedLabel } from 'helpers/translations/translations.helpers';
import AteDatabrowser from 'components/shared/databrowser/AteDatabrowser';
import AteInfiniteScroll from 'components/shared/infiniteScroll/AteInfiniteScroll';
import {
    IAteDatabrowserSingleFilter,
    IAteDatabrowserSingleSort,
} from 'components/shared/databrowser/interfaces/databrowser.interface';
import {
    IAteMyCoursesAndLpWidget,
    MyCoursesAndLpCourseStatusEnum,
    MyCoursesAndLpCourseTypeEnum,
    MyCoursesAndLpViewEnum,
} from 'components/widgets/myCoursesAndLp/interfaces/myCoursesAndLp.interface';
import AteDatabrowserFilter from 'components/shared/databrowser/databrowser-filter/AteDatabrowserFilter';
import AteDatabrowserSearch from 'components/shared/databrowser/databrowser-search/AteDatabrowserSearch';
import AteDatabrowserContent from 'components/shared/databrowser/databrowser-content/AteDatabrowserContent';
import AteDatabrowserDropdownSort
    from 'components/shared/databrowser/databrowser-sort-dropdown/AteDatabrowserDropdownSort';
import AteDatabrowserDropdownFilter
    from 'components/shared/databrowser/databrowser-filter-dropdown/AteDatabrowserDropdownFilter';
import AteCardListItem from 'components/shared/cards/cardListItem/AteCardListItem';
import AteCard from 'components/shared/cards/card/AteCard';
import { AteBlankSlate } from 'components/shared/blankSlate/AteBlankSlate';
import {
    FROM_MYCL_QUERY_PARAM,
    MY_COURSES_AND_LP_DEFAULT_SORT,
    MY_COURSES_AND_LP_FILTERS,
    MY_COURSES_AND_LP_SORTS,
} from 'components/widgets/myCoursesAndLp/helpers/const';
import { getCardMenu } from 'components/shared/cards/helpers/card.helpers';
import { PageInfoEnum, PAGES_INFO } from 'routes/pageSettings';
import { MY_COURSES_AND_LP_TITLE } from 'helpers/translations/labels';
import { getMyCoursesAndLp } from 'api/BackendCalls';
import { updateMyCoursesAndLearnignPlanUUIDActivated, updateMyCoursesAndLpFilters, updateMyCoursesAndLpWidgetSettings } from 'store/app/app.slice';
import { myCourseAndLpItemtoCard } from 'components/widgets/myCoursesAndLp/helpers/MyCoursesAndLpItemsToCards.helper';
import { IMyCoursesAndLpItem } from './interfaces/myCoursesAndLpResponse.interface';
import { getBootstrapStore, getSessionStore } from 'store/helpers';
import { useWindowSize } from 'helpers/commons.helpers';
import { LGZone, XLZone, XSZone } from 'helpers/viewportBreakpoint.helpers';
import { getIsCourseSharingEnabled, getMyCLPById } from 'store/app/app.selectors';
import { openCourseSharingDialog } from 'helpers/sharing.helpers';
import { WidgetTranslationTypeEnum } from 'interfaces/commons.interface';
import { AteLocalize } from 'services/translations/AteLocalize';
import { DICTIONARY } from 'dictionary';
import { getAppCurrentLanguage } from 'store/translations/translations.selector';
import { getDcbLmsDefaultLanguage } from 'store/bootstrap/boostrap.selector';
import { v4 as uuidv4 } from 'uuid';

export function AteMyCoursesAndLp(props: IAteMyCoursesAndLpWidget) {
    const history = useHistory();
    const dispatch = useDispatch();

    const accessToken = useSelector(getDcbAccessToken);
    const baseUrl = useSelector(getBaseUrl);
    const userId = useSelector(getUserId);
    const dcbLmsDefaultPlatformLanguage = useSelector(getDcbLmsDefaultLanguage);
    const userLanguage = useSelector(getAppCurrentLanguage);
    const bootstrap = useSelector(getBootstrapStore);
    const session = useSelector(getSessionStore);
    const isCourseSharingEnabled = useSelector(getIsCourseSharingEnabled);

    const settings = useSelector(state => getMyCLPById(state, props.UUID));

    const [myCoursesAndLpItems, setMyCoursesAndLpItems] = useState<IMyCoursesAndLpItem[]>();
    const [subMyCoursesAndLp, setSubMyCoursesAndLp] = useState<Subscription>();
    const [hasMoreData, setHasMoreData] = useState(true);
    const [hasActiveFilters, setHasActiveFilters] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [isStretchMode, setIsStretchMode] = useState(false);
    const [cardsPerRow, setCardsPerRow] = useState(1);

    const myCLPTitle = props.title ? getWidgetTranslatedLabel(props.title, userLanguage, dcbLmsDefaultPlatformLanguage) : '';
    const myCLPSubtitle = props.description ? getWidgetTranslatedLabel(props.description, userLanguage, dcbLmsDefaultPlatformLanguage) : null;

    const isXSZone = XSZone();
    const isLGZone = LGZone();
    const isXLZone = XLZone();

    const databrowserContentRef = useCallback((node: HTMLDivElement | null) => {
        if (node) {
            calculateCardWidth(node);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [useWindowSize()]);

    const calculateCardWidth = (cardContainer: HTMLElement) => {
        const margin = isLGZone || isXLZone ? 24 : 16;
        const cardMinWidth = 200;
        const cardContainerWidth = cardContainer.clientWidth;
        let maxCardPerRow = Math.floor((cardContainerWidth + margin) / (cardMinWidth + margin));

        if (isXSZone) {
            maxCardPerRow = 2;
        }

        setCardsPerRow(maxCardPerRow);
    };

    useEffect(() => {
        if (props.UUID && props.isDashboardPage) {
            dispatch(updateMyCoursesAndLpWidgetSettings(props));
        }
        return () => {
            setMyCoursesAndLpItems([]);
            subMyCoursesAndLp?.unsubscribe()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (settings) {
            filtersHaveBeenUpdated();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settings]);

    useEffect(() => {
        //TODO: Move it in Redux Reducer
        if (hasActiveFilters && page === 1 && isLoading && !myCoursesAndLpItems?.length) {
            loadMore();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [myCoursesAndLpItems, page, isLoading, hasActiveFilters])

    const loadMore = () => {
        setIsLoading(true);
        subMyCoursesAndLp?.unsubscribe();

        const authConfig: HttpConfig = {
            accessToken,
            baseUrl,
        };

        setSubMyCoursesAndLp(
            getMyCoursesAndLp(
                authConfig,
                page,
                getPageSize(),
                userId,
                props.hide_courses_in_lp ?? true,
                getCoursesType(),
                settings?.filtersSettings?.sort || MY_COURSES_AND_LP_DEFAULT_SORT,
                getCoursesStatus(),
                settings?.filtersSettings?.search,
            ).subscribe((myCoursesAndLp) => {
                setIsLoading(false);
                setHasMoreData(myCoursesAndLp.has_more_data);
                setPage(page + 1);

                const combineItems: IMyCoursesAndLpItem[] = [
                    ...myCoursesAndLpItems ?? [],
                    ...myCoursesAndLp.items
                ]

                setMyCoursesAndLpItems(combineItems);
            }),
        );
    };

    const getPageSize = (): number => {
        if (props.isDashboardPage) {
            return 6;
        }

        return 36;
    };

    const getCoursesType = (): string[] => {
        switch (props.course_type) {
            case MyCoursesAndLpCourseTypeEnum.COURSE:
                return [CourseTypeEnum.E_LEARNING];
            case MyCoursesAndLpCourseTypeEnum.LEARNING_PLAN:
                return [CourseTypeEnum.LEARNING_PLAN];
            case MyCoursesAndLpCourseTypeEnum.ALL:
                return [CourseTypeEnum.E_LEARNING, CourseTypeEnum.LEARNING_PLAN];
        }
    };

    const getCoursesStatus = (): string[] => {
        if (props.course_status === MyCoursesAndLpCourseStatusEnum.ALL) {
            if (settings?.filtersSettings?.filters?.length) {
                return settings?.filtersSettings?.filters;
            }
            return [];
        }
        return [props.course_status];
    };

    const filtersHaveBeenUpdated = () => {
        setMyCoursesAndLpItems([]);
        setHasActiveFilters(true);
        setPage(1);
        setIsLoading(true);
    };

    const searchChanged = (search?: string) => {
        dispatch(updateMyCoursesAndLpFilters({ UUID: settings?.UUID, filters: { search } }));
    };

    const filtersChanged = (filters: IAteDatabrowserSingleFilter[]) => {
        const filtersSelected = filters.map(f => f.value);
        dispatch(updateMyCoursesAndLpFilters({ UUID: settings?.UUID, filters: { filters: filtersSelected } }));
    };

    const sortChanged = (sortSelected: IAteDatabrowserSingleSort) => {
        dispatch(updateMyCoursesAndLpFilters({
            UUID: settings?.UUID,
            filters: {
                sort: sortSelected.value,
            }
        }));
    };

    const saveMyCLPUUID = () => {
        dispatch(updateMyCoursesAndLpWidgetSettings({ ...props, isDashboardPage: false, filtersSettings: {} }))
        dispatch(updateMyCoursesAndLearnignPlanUUIDActivated(props.UUID));
    }

    const viewAllClicked = () => {
        saveMyCLPUUID();
        history.push(PAGES_INFO[PageInfoEnum.MY_COURSES_AND_LP].path);
    };

    const onClickMyCoursesAndLpItem = (item: IMyCoursesAndLpItem) => {
        const pageInfoItem: PageInfoEnum = item.type === CourseTypeEnum.LEARNING_PLAN ?
            PageInfoEnum.LEARNING_PLAN :
            PageInfoEnum.COURSE_PLAYER;

        if (item.type === CourseTypeEnum.E_LEARNING &&
            item.status === CourseStatusEnum.WAITING) {
            return;
        }

        if (!props.isDashboardPage) {
            saveMyCLPUUID();
        }

        history.push({
            pathname: PAGES_INFO[pageInfoItem].path,
            state: { id: item.id },
            search: `?${FROM_MYCL_QUERY_PARAM}=${!props.isDashboardPage}`,
        });
    };

    const getCardsContent = () => {
        return (
            <div className={`content ${props.view === MyCoursesAndLpViewEnum.LIST ? 'cards-list-container' : `cards-container-grid-${cardsPerRow}`}`}>
                {myCoursesAndLpItems?.map((myCoursesAndLpItem, index) =>
                    getCards(myCoursesAndLpItem),
                )}
            </div>
        );
    };

    const shareCourse = (item: IMyCoursesAndLpItem, event: React.SyntheticEvent<HTMLElement, Event>) => {
        event.preventDefault();
        event.stopPropagation();

        openCourseSharingDialog({ id: item.id, name: item.name });
    }

    const getCards = (item: IMyCoursesAndLpItem) => {
        const footerMenuItems = [];

        if (item.type === CourseTypeEnum.E_LEARNING && isCourseSharingEnabled) {
            footerMenuItems.push(
                {
                    key: uuidv4(),
                    content: AteLocalize.getLocalizedLabel(DICTIONARY.shareCourse),
                    onClick: (e: React.SyntheticEvent<HTMLElement, Event>) => shareCourse(item, e),
                },
            )
        }

        if (props.view === MyCoursesAndLpViewEnum.LIST) {
            return (
                <div key={uuidv4()} className={'card-list ui-col-24'}>
                    <AteCardListItem
                        {...myCourseAndLpItemtoCard(item, props.view, bootstrap, session, baseUrl)}
                        key={uuidv4()}
                        footerMenu={getCardMenu(footerMenuItems)}
                        onClick={() => onClickMyCoursesAndLpItem(item)}
                    />
                </div>
            );
        } else {
            return (
                <div key={uuidv4()}
                    className={`card-container`}>
                    <AteCard
                        {...myCourseAndLpItemtoCard(item, props.view, bootstrap, session, baseUrl)}
                        key={uuidv4()}
                        footerMenu={getCardMenu(footerMenuItems)}
                        onClick={() => onClickMyCoursesAndLpItem(item)}
                    />
                </div>
            );
        }
    };

    const onSetStretchMode = (isVisible: boolean): void => {
        if (props.hideFiltersLabel) {
            setIsStretchMode(true)
            return;
        }

        setIsStretchMode(isVisible);
    }

    const getMyCLPContent = () => {
        return (
            <div data-testid="ate-my-courses-and-learning-plans-content"
                ref={databrowserContentRef}>
                {myCoursesAndLpItems?.length ?
                    <>
                        {
                            props.isDashboardPage ?
                                getCardsContent() :
                                <AteInfiniteScroll
                                    isLoading={isLoading}
                                    loadMore={loadMore}
                                    hasMoreData={hasMoreData}
                                >
                                    {getCardsContent()}
                                </AteInfiniteScroll>
                        }
                    </>
                    : <AteBlankSlate
                        title={{
                            type: WidgetTranslationTypeEnum.SINGLE,
                            value: AteLocalize.getLocalizedLabel(DICTIONARY.blankSlateWhoopsNoResultFound),
                        }}
                        subtitle={{
                            type: WidgetTranslationTypeEnum.SINGLE,
                            value: AteLocalize.getLocalizedLabel(DICTIONARY.blankSlatePleaseTryAgainUsingDifferentSearchCriteriaOrFilters),
                        }}
                        illustrationName='no-item-found'
                    />
                }
            </div>
        )
    }

    return (
        <div className={`my-courses-and-learning-plans`}>
            {
                (props.isDashboardPage || myCLPTitle || myCLPSubtitle) &&
                <Flex
                    className={`info-container ${props.isDashboardPage ? 'databrowser-visible' : ''}`}
                    column>
                    {
                        <StyledText
                            weight={'bold'}
                            size={'large'}
                            className={'title'}
                        >
                            {!props.isDashboardPage ? AteLocalize.getLocalizedLabel(MY_COURSES_AND_LP_TITLE) : myCLPTitle}
                        </StyledText>
                    }
                    {
                        (props.isDashboardPage && myCLPSubtitle) &&
                        <StyledText
                            size={'medium'}
                            className={'subtitle'}
                        >
                            {myCLPSubtitle}
                        </StyledText>
                    }
                </Flex>
            }
            {
                !myCoursesAndLpItems ?
                    <Spinner />
                    :
                    <>
                        {
                            (myCoursesAndLpItems.length || (!myCoursesAndLpItems.length && hasActiveFilters)) ?
                                props.isDashboardPage ?
                                    <AteDatabrowser
                                        showViewAllButton={true}
                                        isLoading={isLoading && !myCoursesAndLpItems.length}
                                        onViewAllClick={viewAllClicked}
                                        onSetStretchMode={onSetStretchMode}
                                    >
                                        <AteDatabrowserFilter>
                                            {
                                                props.course_status === MyCoursesAndLpCourseStatusEnum.ALL &&
                                                <AteDatabrowserDropdownFilter
                                                    isStretchMode={isStretchMode}
                                                    onFiltersChange={filtersChanged}
                                                    filters={MY_COURSES_AND_LP_FILTERS}
                                                />
                                            }

                                            <AteDatabrowserDropdownSort
                                                isStretchMode={isStretchMode}
                                                sorts={MY_COURSES_AND_LP_SORTS}
                                                onSortChange={sortChanged}
                                            />

                                            <AteDatabrowserSearch
                                                isStretchMode={isStretchMode}
                                                valueToSearch={settings?.filtersSettings?.search}
                                                onTextSearchSubmit={searchChanged}
                                            />
                                        </AteDatabrowserFilter>

                                        <AteDatabrowserContent>
                                            {getMyCLPContent()}
                                        </AteDatabrowserContent>

                                    </AteDatabrowser> :
                                    isLoading && !myCoursesAndLpItems.length ?
                                        <div className="loader">
                                            <Spinner />
                                        </div> :
                                        getMyCLPContent()

                                : <AteBlankSlate
                                    title={{
                                        type: WidgetTranslationTypeEnum.SINGLE,
                                        value: AteLocalize.getLocalizedLabel(DICTIONARY.blankSlateWhoopsThereSNothingHere),
                                    }}
                                    subtitle={{
                                        type: WidgetTranslationTypeEnum.SINGLE,
                                        value: AteLocalize.getLocalizedLabel(DICTIONARY.blankSlateLooksLikeThereSNothingForYouToDoInThisAreaRightNow),
                                    }}
                                    illustrationName='take-a-break'
                                />
                        }
                    </>
            }
        </div >
    );
}
