// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import languageFormats from './assets/language-formats.json';
import localeFormats from './assets/locale-formats.json';
import { HDate } from './h-date/h-date';
import { DateTimeFormats, DateTimeLength, HDateLocale } from './h-date/h-date.interface';
import { ISession } from 'interfaces/Session';
import { IBootstrap } from 'interfaces/Bootstrap';

// MySQL default date and time formats.
export const MYSQL_DATE_FORMAT = 'YYYY-MM-DD';
export const MYSQL_TIME_FORMAT = 'HH:mm:ss';
export const MYSQL_DATE_TIME_FORMAT = `${MYSQL_DATE_FORMAT} ${MYSQL_TIME_FORMAT}`;

// Default date and time lengths.
export const DEFAULT_DATE_LENGTH = DateTimeLength.SHORT;
export const DEFAULT_TIME_LENGTH = DateTimeLength.MEDIUM;

/**
 * Returns the context timezone.
 * If current session is valid, user's preferred timezone gets returned.
 * Otherwise, platform's default timezone gets returned.
 * @returns The context timezone.
 */
export function getContextTimezone(bootstrap: IBootstrap, session: ISession): string {
    return session ?
        session.timezone :
        bootstrap.settings.timezone;
}

/**
 * Returns the context locale.
 * If current session is valid, user's preferred locale gets returned.
 * Otherwise, platform's default locale gets returned.
 * @returns The context locale.
 */
export function getContextLocale(bootstrap: IBootstrap, session: ISession): string {
    let contextLocale = '';

    if (bootstrap && bootstrap.user_settings) {
      switch (bootstrap.user_settings.date_format) {
        case 'browser_code':
          contextLocale = session.dateformat_locale;
          break;
        case 'user_selected':
          contextLocale = session ?
            session.dateformat_locale :
            bootstrap.settings.dateformat_locale;
          break;
        case 'custom':
          contextLocale = bootstrap.settings.dateformat_locale;
          break;
      }
    }

    if (!contextLocale || contextLocale === '') {
      contextLocale = 'en-us';
    }

    return contextLocale;
}

/**
 * Returns the context language.
 * If current session is valid, user's preferred language gets returned.
 * Otherwise, platform's default language gets returned.
 * @returns The context language.
 */
export function getContextLanguage(bootstrap: IBootstrap, session: ISession): string {
    return session ?
        session.language :
        bootstrap.default_platform_language;
}

/**
 * Sets locale for HDate objects.
 * Note: all HDate objects will be affected by this setting.
 * @param language The language you want to associate to the locale info.
 * @param locale The locale settings.
 */
export function setLocale(language: string, locale: HDateLocale): void {
    HDate.setLocale(language, locale);
}

/**
 * Returns the list of locales for a specified language.
 * @param languageCode The language for which you need locales.
 * @param bootstrap
 * @param session
 * @returns An array containing all locales for the specified language.
 */
export function getLocalesByLanguage(
    languageCode: string,
    bootstrap: IBootstrap,
    session: ISession): string[] {

    if (!(languageCode in languageFormats)) {

        console.error(`No datetime formats configured for language with code / locale of ${languageCode}!`);

        if (languageCode === getContextLanguage(bootstrap, session)) {
            return [];
        }

        console.error(`Fallback to default platform language: ${getContextLanguage(bootstrap, session)}!`);

        return getLocalesByLanguage(getContextLanguage(bootstrap, session), bootstrap, session);
    }

    return languageFormats[languageCode]['date_formats'];
}

/**
 * Returns all the formats for a specified locale.
 * @param locale The locale for which you need formats.
 * @returns An array containing all formats for the specified locale.
 */
export function getFormatsByLocale(
    locale: string
): DateTimeFormats {

    return localeFormats[locale];
}

/**
 * Given a locale and the preferred date and time lengths, it returns the corresponding date-time format.
 * @param locale The locale for which you need the date time format.
 * @param dateLength Date length.
 * @param timeLength Time length.
 * @returns The corresponding date-time format.
 */
export function getDateTimeFormat(
    locale: string,
    dateLength: DateTimeLength,
    timeLength: DateTimeLength): string {

    const cleanLocale = getCleanLocale(locale);
    const shortLocale = getShortLocale(locale);

    const formatObject =
      localeFormats[cleanLocale] ?
          localeFormats[cleanLocale] :
          localeFormats[shortLocale];

    if (formatObject) {
        if (dateLength !== timeLength) {
            let format = formatObject ? formatObject['dateTimeFormat'] : null;

            if (format) {
                format = format.replace('{1}', formatObject[dateLength]['date']);
                format = format.replace('{0}', formatObject[timeLength]['time']);
            }

            return format;
        }

        return formatObject[dateLength]['dateTime'];
    } else {
        console.error(`Warning: locale ${locale} not found.`);

        return '';
    }
}

/**
 * Given a locale and the preferred date length, it returns the corresponding date format.
 * @param locale The locale for which you need the date time format.
 * @param dateLength Date length.
 * @returns The corresponding date format.
 */
export function getDateFormat(
    locale: string,
    dateLength: DateTimeLength,
): string {

    const cleanLocale = getCleanLocale(locale);
    const shortLocale = getShortLocale(locale);

    const formatObject =
        localeFormats[cleanLocale] ?
            localeFormats[cleanLocale] :
            localeFormats[shortLocale];

    if (formatObject) {
        return formatObject[dateLength]['date'];
    } else {
        console.error(`Warning: locale ${locale} not found.`);

        return '';
    }
}

/**
 * Given a locale and the preferred time length, it returns the corresponding time format.
 * @param locale The locale for which you need the time format.
 * @param timeLength Time length.
 * @returns The corresponding time format.
 */
export function getTimeFormat(
    locale: string,
    timeLength: DateTimeLength,
): string {
    const cleanLocale = getCleanLocale(locale);
    const shortLocale = getShortLocale(locale);

    const formatObject =
        localeFormats[cleanLocale] ?
            localeFormats[cleanLocale] :
            localeFormats[shortLocale];

    if (formatObject) {
        return formatObject[timeLength]['time'];
    } else {
        console.error(`Warning: locale ${locale} not found.`);

        return '';
    }
}

/**
 * Transforms the locale string in order for it to fit with locale-formats json definition
 * @param locale The locale string
 * @returns The transformed locale string
 */
export function getCleanLocale(
  locale: string
): string {
  locale = locale.replace(/-/g, '_')
    .toLowerCase();
  const localeParts = locale.split('_');

  if (
    localeParts[0] &&
    localeParts[1] &&
    localeParts[0] === localeParts[1]) {

    return localeParts[0];
  } else {
    return locale;
  }
}

/**
 * Returns the first part of the locale string
 * @param locale The locale string
 * @returns The first part of the locale string
 */
export function getShortLocale(
  locale: string,
): string {
  locale = locale.replace(/-/g, '_')
    .toLowerCase();

  return locale.split('_')[0];
}

/**
 * Formats a HDate as a date-time with automatically calculated info about language, locale, format and timezone.
 * If current session is valid, user's settings are used.
 * Otherwise, platform's default settings are used.
 * @param date The HDate object you want to format.
 * @param bootstrap
 * @param session
 * @returns The formatted date-time string.
 */
export function formatWithUserContext(
    date: HDate,
    bootstrap: IBootstrap,
    session: ISession
): string {

    return date.formatTimezoned(
        getDateTimeFormat(getContextLocale(bootstrap, session), DEFAULT_DATE_LENGTH, DEFAULT_TIME_LENGTH),
        getContextTimezone(bootstrap, session));
}

/**
 * Formats a HDate as a date with automatically calculated info about language, locale, format and timezone.
 * If current session is valid, user's settings are used.
 * Otherwise, platform's default settings are used.
 * @param date The HDate object you want to format.
 * @param bootstrap
 * @param session
 * @returns The formatted date string.
 */
export function formatDateWithUserContext(
    date: HDate,
    bootstrap: IBootstrap,
    session: ISession
): string {

    return date.formatTimezoned(
        getDateFormat(getContextLocale(bootstrap, session), DEFAULT_DATE_LENGTH),
        getContextTimezone(bootstrap, session));
}

/**
 * Formats a HDate as a time with automatically calculated info about language, locale, format and timezone.
 * If current session is valid, user's settings are used.
 * Otherwise, platform's default settings are used.
 * @param date The HDate object you want to format.
 * @param bootstrap
 * @param session
 * @returns The formatted time string.
 */
export function formatTimeWithUserContext(
    date: HDate,
    bootstrap: IBootstrap,
    session: ISession
): string {

    return date.formatTimezoned(
        getTimeFormat(getContextLocale(bootstrap, session), DEFAULT_TIME_LENGTH),
        getContextTimezone(bootstrap, session));
}

/**
 * Formats a HDate as a date-time using MySQL date-time format and UTC timezone.
 * @param date The HDate object you want to format.
 * @returns The formatted date-time string.
 */
export function formatMySQL(
    date: HDate
): string {

    return date.formatUTC(MYSQL_DATE_TIME_FORMAT);
}

/**
 * Formats a HDate as a date using MySQL date format and UTC timezone.
 * @param date The HDate object you want to format.
 * @returns The formatted date string.
 */
export function formatDateMySQL(
    date: HDate
): string {

    return date.formatUTC(MYSQL_DATE_FORMAT);
}

/**
 * Formats a HDate as a time using MySQL time format and UTC timezone.
 * @param date The HDate object you want to format.
 * @returns The formatted time string.
 */
export function formatTimeMySQL(
    date: HDate
): string {

    return date.formatUTC(MYSQL_TIME_FORMAT);
}
