import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { getHttpClient, HttpConfig } from './HttpClient';
import { IBootstrap } from 'interfaces/Bootstrap';
import { ISession } from 'interfaces/Session';
import { AxiosResponse } from 'axios';
import { ICourseInfo } from 'interfaces/course.interface';
import { IMyCoursesAndLpResponse } from 'components/widgets/myCoursesAndLp/interfaces/myCoursesAndLpResponse.interface';
import { ErrorTokenEnum, IAuthTokenResponse, IErrorTokenResponse } from 'interfaces/Auth';
import { ILearningPlan } from 'interfaces/learningPlan.interface';
import { IConfigResponse } from 'components/pages/dashboard/interfaces/layout.response';

/**
 * Retrieve a valid Bearer Token for Current User via JWT
 * @param authConfig
 * @param tenantId
 * @returns
 */
export const getAccessToken = (authConfig: HttpConfig, tenantId: string): Observable<IAuthTokenResponse> => {
    const baseUrl = authConfig.baseUrl;
    const payload = {
        "grant_type": "urn:ietf:params:oauth:grant-type:jwt-bearer",
        "client_id": `eris_api_${tenantId}`,
        "assertion": authConfig.accessToken,
        "scope": "api"
    }

    return from(getHttpClient({ baseUrl }).post<AxiosResponse>('/manage/v1/oauth2/token', payload))
        .pipe(
            map(({ data }) => {
                const response: IErrorTokenResponse | IAuthTokenResponse = data.data;

                //User not found on the platform
                if ((response as IErrorTokenResponse).error === ErrorTokenEnum.INVALID_GRANT) {
                    throw Error(ErrorTokenEnum.INVALID_GRANT)
                };

                return response as IAuthTokenResponse;
            })
        );
}

/**
 * Retrieve main info about LMS Platform
 * @param authConfig
 * @returns
 */
export const getBootstrap = (authConfig: HttpConfig): Observable<IBootstrap> => {
    return from(getHttpClient(authConfig).get<AxiosResponse<IBootstrap>>('/manage/v1/site/bootstrap'))
        .pipe(
            map(({ data }) => data.data)
        );
}

/**
 * Retrieve main info about Current User logged on LMS
 * @param authConfig
 * @returns
 */
export const getSession = (authConfig: HttpConfig): Observable<ISession> => {
    return from(getHttpClient(authConfig).get<AxiosResponse<ISession>>('/manage/v1/user/session'))
        .pipe(
            map(({ data }) => data.data)
        );
}

/**
 * Retrieve Courses and Learning Plan data for the current user customized to settings
 *
 * @param authConfig
 * @param page
 * @param page_size
 * @param userId
 * @param hideCourseInLp
 * @param type
 * @param sort_by
 * @param status
 * @param search
 * @returns
 */
export const getMyCoursesAndLp = (
    authConfig: HttpConfig,
    page: number,
    page_size: number,
    userId: number,
    hideCourseInLp: boolean,
    type: string[],
    sort_by: string,
    status?: string[],
    search?: string
): Observable<IMyCoursesAndLpResponse> => {
    const params: any = {
        page,
        page_size,
        exclude_courses_in_lp: hideCourseInLp,
        with_visibility: 'both',
        type,
        sort_by,
    };

    if (status) {
        params['status'] = status;
    }
    if (search) {
        params['search_text'] = search;
    }

    return from(getHttpClient(authConfig).get<AxiosResponse<IMyCoursesAndLpResponse>>(`/loki/widget/v1/courses/${userId}`, { params }))
        .pipe(
            map(({ data }) => data.data),
        );
}

/**
 * Retrieve every according to Course ID passed
 * @param authConfig
 * @param courseId
 * @returns
 */
export const getCourse = (authConfig: HttpConfig, courseId: number): Observable<ICourseInfo> => {
    return from(getHttpClient(authConfig).get<AxiosResponse<ICourseInfo>>(`/learn/v1/courses/${courseId}`))
        .pipe(
            map(({ data }) => data.data)
        );
}

/**
 * Enroll an User to a specific course with Learner(3) level
 * @param authConfig
 * @param courseId
 * @param userId
 * @returns
 */
export const enrollmentUserInCourse = (authConfig: HttpConfig, courseId: number, userId: number): Observable<void> => {
    return from(getHttpClient(authConfig).post<AxiosResponse<void>>(`/learn/v1/enrollments/${courseId}/${userId}`, { level: 3 }))
        .pipe(
            map(({ data }) => data.data)
        );
}

/**
 * Retrieve every info about to Learning Plan ID passed
 * @param authConfig
 * @param learningPlanId
 * @returns
 */
export const getLearningPlan = (authConfig: HttpConfig, learningPlanId: number): Observable<ILearningPlan> => {
    return from(getHttpClient(authConfig).get<AxiosResponse<ILearningPlan>>(`/learn/v1/lp/${learningPlanId}`))
        .pipe(
            map(({ data }) => data.data)
        );
}

/**
 * Retrieve the main settings of Flow for Microsoft Teams according to Tenant ID passed
 * - Retrieve the dashboard layout
 * - Retrieve the custom domain for an User (if Multidomain APP is active) else the original domain
 * @param authConfig
 * @param tenantId
 * @returns
 */
export const getTenantConfig = (authConfig: HttpConfig, tenantId: string): Observable<IConfigResponse> => {
    return from(getHttpClient(authConfig).get<AxiosResponse<IConfigResponse>>('/setup/v1/flow/msteams/dashboards/config', { params: { tenant_id: tenantId } }))
        .pipe(
            map(({ data }) => data.data)
        );
}
