import { DcbTranslations } from "interfaces/Translations";
import AteLocalizable from "./AteLocalizable";

export const DEFAULT_LANGUAGE = 'en';

export class AteLocalize {
    private static _languageInUse: string;
    private static _translations: DcbTranslations;

    private static _variablesTemplateMatcher = new RegExp('\\{\\s?([^{}\\s]*)\\s?\\}', 'g');

    static setLanguageInUse(language: string) {
        this._languageInUse = language;
    }

    static setTranslations(translations: DcbTranslations) {
        this._translations = translations;
    }

    /**
     * Do not use this method outside of React components, otherwise the labels will not be translated.
     * @param translation
     * @param language
     * @returns
     */
    static getLocalizedLabel(
        translation: AteLocalizable | string,
        language?: string
    ): string {
        if (!(translation instanceof AteLocalizable)) {
            return translation;
        }

        let translated = '';

        if (!language) {
            language = this._languageInUse ?? DEFAULT_LANGUAGE;
        }

        if (!translation?.module || !translation.phrase) {
            /* If there isn't a valid module or phrase returns an empty string */
            return translated;
        } else if (
            !this._translations ||
            !this._translations[language] ||
            !this._translations[language][translation.module] ||
            !this._translations[language][translation.module][translation.phrase]
        ) {
            /**
             * If the are no translations for the
             * current module, prase or current language
             * set the phrase value as `translated`
             */
            translated = translation.phrase;
        } else {
            /* Set the correct translation value from the translations available to `translated` */
            translated =
                this._translations[language][translation.module][translation.phrase];
        }

        return this._applyVariables(translated, translation.variables);
    }

    private static _applyVariables(
        translation: string,
        variables: { [key: string]: string | number },
    ): string {
        if (!!Object.keys(variables).length) {
            return translation.replace(
                this._variablesTemplateMatcher,
                (substring: string, k: string) => {
                    return variables[k]?.toString() ?? substring;
                },
            );
        } else {
            return translation;
        }
    }
}
