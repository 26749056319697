import { AteEpics } from "store/middleware";
import { initTranslations, updateCustomTranslations, updateLanguageInMSTeamsApp, updateReadyStatus, updateTranslations, updateUserLanguageInLMS } from "./translations.slice";
import { catchError, concatMap, filter, map, mergeMap } from "rxjs/operators";
import { getCustomTranslation, getStaticTranslation } from "api/Translations";
import { of } from "rxjs";
import _ from 'lodash';
import { getMSTeamsUserLanguage } from "helpers/translations/translations.helpers";
import { DcbTranslations } from "interfaces/Translations";

/**
 * When the initTranslations action is dispatched.
 * The static translation file is retrieved.
 * Afterwards, the user's language on MSTeams and static translations are dispatched.
 * @param action$
 * @param state$
 * @returns
 */
export const setStaticTranslations: AteEpics = (action$, state$) =>
    action$.pipe(
        filter(initTranslations.match),
        concatMap(({ payload }) =>
            getStaticTranslation(payload)
                .pipe(catchError(() => of({})))
        ),
        mergeMap(translations =>
            [
                updateLanguageInMSTeamsApp(getMSTeamsUserLanguage(translations, state$.value.msContext.app.locale)),
                updateTranslations(translations),
            ]
        ),
    )

export const updateTranslationsStatus: AteEpics = action$ =>
    action$.pipe(
        filter(updateTranslations.match),
        map(() => updateReadyStatus(true)),
    );

/**
 * Upon user login to the LMS, the translations are retrieved from the localization tool.
 * These are merged with static translations.
 * @param action$
 * @param state$
 * @returns
 */
export const mergeCustomTranslations: AteEpics = (action$, state$) =>
    action$.pipe(
        filter(updateCustomTranslations.match),
        map(({ payload }) => {
            const customTranslations = payload;
            const translationsReducer = state$.value.translations;

            const staticTranslations = _.cloneDeep(translationsReducer.translations![translationsReducer.languageInUse]);

            const mergedTranslations = _.merge(staticTranslations, customTranslations);

            return updateTranslations({ [translationsReducer.languageInUse]: mergedTranslations });
        })
    )

/**
 * When the User Language on DoceboLMS is saved on Translations reducer,
 * if the user's language set in the MSTeams APP has not been retrieved because it was not found,
 * this is used as the app language.
 * Trying to fetch the static translation file and the translations from the localization tool again.
 * If this is also not found, the default language (English) is used.
 * @param action$
 * @param state$
 * @returns
 */
export const updateTranslationsAccordingUserLanguageInLMS: AteEpics = (action$, state$) =>
    action$.pipe(
        filter(updateUserLanguageInLMS.match),
        filter(() => state$.value.translations.languageInUse !== state$.value.translations.userLanguageInMSTeamsApp),
        concatMap(({ payload }) =>
            getStaticTranslation(payload)
                .pipe(
                    catchError(() => of({})),
                    mergeMap(async (staticTranlations: DcbTranslations) => {
                        const customTranslations = await getCustomTranslation(
                            {
                                accessToken: state$.value.auth.dcbAccessToken.token,
                                baseUrl: state$.value.auth.baseUrl
                            },
                            payload)
                            .toPromise();

                        return {
                            [payload]: _.merge(staticTranlations[payload], customTranslations),
                        }
                    }),
                )
        ),
        map((translations) => updateTranslations(translations)),
    )

export const TRANSLATIONS_EPICS: AteEpics[] = [
    setStaticTranslations,
    updateTranslationsStatus,
    mergeCustomTranslations,
    updateTranslationsAccordingUserLanguageInLMS
]
