import { Button } from '@fluentui/react-components';
import { Flex } from '@fluentui/react-migration-v0-v9';
import { AteBlankSlate } from "components/shared/blankSlate/AteBlankSlate";
import { WidgetTranslationTypeEnum } from "interfaces/commons.interface";
import { useEffect, useState } from "react"
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom"
import { getOriginalPath } from "store/app/app.selectors";
import { getUserNeedToAcceptPolicy, getUserNeedToAcceptTermsAndConditions } from "store/auth/auth.selectors";
import PrivacyPolicyDialog from "./dialogs/PrivacyPolicyDialog/PrivacyPolicyDialog";
import TermsAndConditionsDialog from "./dialogs/TermsAndConditionsDialog/TermsAndConditionsDialog";
import './PoliciesPage.scss';
import { AteLocalize } from "services/translations/AteLocalize";
import { DICTIONARY } from "dictionary";

export default function PoliciesPage() {
    const history = useHistory();

    const originalPath = useSelector(getOriginalPath);
    const userNeedToAcceptPolicy = useSelector(getUserNeedToAcceptPolicy);
    const userNeedToAcceptTermsAndConditions = useSelector(getUserNeedToAcceptTermsAndConditions);

    const [isPrivacyPolicyDialogOpened, setIsPrivacyPolicyDialogOpened] = useState<boolean>(false);
    const [isTermsAndConditionsDialogOpened, setIsTermsAndConditionsDialogOpened] = useState<boolean>(false);

    useEffect(() => {
        if (!userNeedToAcceptPolicy && !userNeedToAcceptTermsAndConditions) {
            history.push(originalPath);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userNeedToAcceptPolicy, userNeedToAcceptTermsAndConditions]);

    const onPrivacyPolicyDialogStatusChanged = (isPrivacyPolicyDialogVisible: boolean, isPrivacyPolicyAccepted?: boolean) => {
        setIsPrivacyPolicyDialogOpened(isPrivacyPolicyDialogVisible);

        if (!isPrivacyPolicyDialogVisible && isPrivacyPolicyAccepted && userNeedToAcceptTermsAndConditions) {
            setIsTermsAndConditionsDialogOpened(true);
        }
    }

    const openTermsUseDialog = (): void => {
        if (userNeedToAcceptPolicy) {
            setIsPrivacyPolicyDialogOpened(true);
            return;
        }

        setIsTermsAndConditionsDialogOpened(true);
    }

    return (
        <div className="ate-policies-page">
            <Flex
                className="policies-blankslate"
                hAlign="center"
                vAlign="center"
                column>
                <AteBlankSlate
                    title={{
                        type: WidgetTranslationTypeEnum.SINGLE,
                        value: AteLocalize.getLocalizedLabel(DICTIONARY.blankSlateAccessDenied),
                    }}
                    subtitle={{
                        type: WidgetTranslationTypeEnum.SINGLE,
                        value: AteLocalize.getLocalizedLabel(DICTIONARY.blankSlateYouDoNotYetPermissionToAccessThisSection),
                    }}
                    illustrationName='denied'
                />
                <Button onClick={openTermsUseDialog}>
                    {AteLocalize.getLocalizedLabel(DICTIONARY.goToTermsOfUse)}
                </Button>
            </Flex>
            {
                userNeedToAcceptPolicy &&
                <PrivacyPolicyDialog
                    isDialogVisible={isPrivacyPolicyDialogOpened}
                    onDialogVisibilityStatusChanges={onPrivacyPolicyDialogStatusChanged}
                />
            }
            {
                userNeedToAcceptTermsAndConditions &&
                <TermsAndConditionsDialog
                    isDialogVisible={isTermsAndConditionsDialogOpened}
                    onDialogVisibilityStatusChanges={setIsTermsAndConditionsDialogOpened}
                />
            }
        </div>
    )
}
