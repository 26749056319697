import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { getIsFlowSDKReady } from 'store/app/app.selectors';
import { ICoursePlayer } from './interface/coursePlayer.interface';
import './AteCoursePlayer.scss';

export enum TrainingMaterialDownloadEnum {
    LINK = 'link',
    BLOB = 'blob'
}

/**
 * Usage Example: <AteCoursePlayer idPlayer="player" courseId={181} autoplay={true} />
 */
export default function AteCoursePlayer(props: ICoursePlayer) {
    const isFlowSDKReady = useSelector(getIsFlowSDKReady);

    return (
        <>
            {
                isFlowSDKReady &&
                <dcbo-course-player
                    data-testid="course-player"
                    id={props.idPlayer}
                    courseid={props.courseId}
                    autoplay={props.autoplay}
                    forcefullscreen={true}
                    showfullscreen={false}
                    showpreview={false}
                    showcoursedeadline={true}
                    tmdownloadmode={isMobile ? TrainingMaterialDownloadEnum.LINK : TrainingMaterialDownloadEnum.BLOB}
                    style={{
                        "--dcb-flow-scp-fullscreen-position": "absolute",
                        "--dcb-flow-course-player-fullscreen": 999
                    }}>
                </dcbo-course-player>
            }
        </>
    )
}
