export type KeyValueObject = { [key: string]: any };
export type KeyStringObject = { [key: string]: string };

export type WidgetTranslationType = "single_value" | "multi_lang";
export enum WidgetTranslationTypeEnum {
    MULTIPLE = 'multi_lang',
    SINGLE = 'single_value'
}

export interface IWidgetTranslationValues {
    [key: string]: string;
}

export interface IWidgetTranslationLabelSettings {
    type: WidgetTranslationType,
    value: string,
    values?: IWidgetTranslationValues
}

export type vAlignType = keyof typeof VerticalAlignValueToFlexEnum;
export type hAlignType = keyof typeof HorizontalAlignValueToFlexEnum;

export enum HorizontalAlignValueToFlexEnum {
    "left" = "flex-start",
    "center" = "center",
    "right" = "flex-end"
}

export enum VerticalAlignValueToFlexEnum {
    "top-bottom" = "space-between",
    "middle" = "center",
}

export interface IApiResponse {
    count: number;
    current_page: number;
    current_page_size: number;
    has_more_data: boolean;
    cursor: string;
    total_page_count: number;
    sort?: {
        sort_attr: string;
        sort_dir: string;
    };
}
