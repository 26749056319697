import { DcbTranslations } from "interfaces/Translations";
import { IWidgetTranslationLabelSettings, WidgetTranslationTypeEnum } from "interfaces/commons.interface";

/**
 * The content of the widgets can be in Multi Language.
 * This method returns the widget content in the language in which this application is set.
 * If this language is not available in the widget, it returns the content to the default language of the LMS.
 * @param labelSettings
 * @param userPreferredLanguage
 * @param defaultLanguageWidget
 * @returns
 */
export const getWidgetTranslatedLabel = (labelSettings: IWidgetTranslationLabelSettings, userPreferredLanguage: string, defaultLanguageWidget: string): string => {
    switch (labelSettings.type) {
        case WidgetTranslationTypeEnum.SINGLE:
            return labelSettings.value;
        case WidgetTranslationTypeEnum.MULTIPLE:
            return labelSettings.values ? labelSettings.values[userPreferredLanguage] || labelSettings.values[defaultLanguageWidget] : '';
        default:
            return '';
    }
}

/**
 * Returns the user's current language on the MSTeams APP.
 * Returns the MSTeams Local (languageId-countryId format) if this translation was found.
 * Otherwise it just returns languageId.
 * @param translations
 * @param msTeamsLocal
 * @returns
 */
export const getMSTeamsUserLanguage = (translations: DcbTranslations, msTeamsLocal: string): string => {
    const translationsLanguage = Object.getOwnPropertyNames(translations)[0];

    const languageId = msTeamsLocal.split('-')[0];
    if (translationsLanguage === languageId) {
        return languageId;
    }

    return msTeamsLocal;
}

