import axios, { AxiosInstance } from "axios";
import { HDate } from "helpers/h-date/h-date/h-date";
import { AuthErrorEnum, ErrorTokenEnum } from "interfaces/Auth";
import { Observable, from, lastValueFrom, throwError } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { setDcbAccessToken, updateAuthenticationError } from "store/auth/auth.slice";
import store from "store/store";
import { getAccessToken, getSession } from "./BackendCalls";
import { getLMSTokenViaEris, setCachedAccessToken } from "./Eris";
import { authentication } from "@microsoft/teams-js";

export interface HttpConfig {
    baseUrl?: string;
    accessToken?: string;
}

enum HTTP_ERROR_STATUS {
    UNAUTHORIZED = 401,
    FORBIDDEN = 403
}

export function getHttpClient(config: HttpConfig, isErisCall = false): AxiosInstance {
    const axiosClient = axios.create({
        baseURL: isErisCall ? process.env.REACT_APP_ERIS_BASE_URL : `https://${config.baseUrl}`,
    })

    if (config.accessToken) {
        axiosClient.defaults.headers['Authorization'] = `Bearer ${config.accessToken}`;
    }

    axiosClient.interceptors.response.use(
        response => response,
        async error => {
            const originalRequest = error.config;
            const responseErrorStatus = error.response?.status;

            if (
                responseErrorStatus === HTTP_ERROR_STATUS.UNAUTHORIZED &&
                !originalRequest._retry &&
                !isErisCall
            ) {
                originalRequest._retry = true;
                const accessToken = await lastValueFrom(refreshDoceboLearnToken());
                originalRequest.headers['Authorization'] = `Bearer ${accessToken}`;
                return axiosClient(originalRequest);
            }

            return Promise.reject(error);
        }
    );

    return axiosClient;
}

export const refreshDoceboLearnToken = (): Observable<string> => {
    const msContext = store.getState().msContext;
    const dispatch = store.dispatch;

    return new Observable(observer => {
        from(authentication.getAuthToken())
            .pipe(
                mergeMap(azureAccessToken => {
                    return getLMSTokenViaEris(azureAccessToken, true)
                        .pipe(map(res => { return { ...res, azureAccessToken } }));
                }),
                mergeMap(erisResponse => {
                    return getAccessToken(
                        {
                            baseUrl: erisResponse.platform_url,
                            accessToken: erisResponse.jwt
                        }, msContext.user!.tenant!.id)
                        .pipe(
                            map(res => {
                                return {
                                    ...res,
                                    azureAccessToken: erisResponse.azureAccessToken,
                                    baseUrl: erisResponse.platform_url
                                }
                            })
                        );
                }),
                mergeMap(async res => {
                    const azureAccessToken = res.azureAccessToken;
                    const accessToken = res.access_token;

                    const { id } = await lastValueFrom(getSession({ baseUrl: res.baseUrl, accessToken }));
                    await lastValueFrom(setCachedAccessToken(azureAccessToken, accessToken, id));

                    dispatch(setDcbAccessToken({ token: accessToken }));

                    return accessToken
                }),
                catchError(err => {
                    if (err && err.message === ErrorTokenEnum.INVALID_GRANT) {
                        dispatch(updateAuthenticationError(AuthErrorEnum.USER_NOT_FOUND));
                    }

                    observer.error();
                    return throwError(err);
                })
            ).subscribe(acessToken => {
                observer.next(acessToken);
                observer.complete();
            });
    })
}

export const isAccessTokenValid = (dcbAccessTokenCreationTime: number) => {
    const minDifferenceInMinutesToValidateToken = 30;
    const differenceInMinutes = new HDate().getDifference(new HDate(dcbAccessTokenCreationTime), 'm');

    return differenceInMinutes <= minDifferenceInMinutesToValidateToken;
}
