import { RootState } from '../reducers';
import { IAteMyCoursesAndLpWidget } from 'components/widgets/myCoursesAndLp/interfaces/myCoursesAndLp.interface';
import { ILearningPlan } from 'interfaces/learningPlan.interface';
import { MSTeamsThemes } from 'components/App';
import { IAteStaticCourseListWidget } from 'components/widgets/staticCourseList/interfaces/staticCourseList.interface';
import { createSelector } from '@reduxjs/toolkit';

export const getLearningPlanSettings = (state: RootState): ILearningPlan | undefined => state.app.learningPlanSettings;
export const getMsTeamsThemeSelected = (state: RootState): MSTeamsThemes | undefined => state.app.msTeamsThemeSelected;
export const getIsFlowSDKReady = (state: RootState): boolean => state.app.isFlowSDKReady;
export const getOriginalPath = (state: RootState): string => state.app.originalPath;
export const getIsTaskModuleMode = (state: RootState): boolean => state.app.isTaskModuleMode;
export const getIsCourseSharingEnabled = (state: RootState): boolean => state.app.isCourseSharingEnabled;
export const getStaticCourseListPageSettings = (state: RootState): IAteStaticCourseListWidget | undefined => state.app.staticCourseListPageSettings;

//MyCLPSelectors
export const getMyCoursesAndLpWidgetSettings = (state: RootState): IAteMyCoursesAndLpWidget[] | undefined => state.app.myCourseAndLpWidgetSettings;
export const getUUIDMyCLPActivated = (state: RootState): string => state.app.myCLPActivatedUUID || '';
export const getMyCLPById = createSelector(
    [getMyCoursesAndLpWidgetSettings, (state, UUID) => UUID],
    (items, myCLPId) => items?.find(item => item.UUID === myCLPId)
);
