import axios from "axios";
import { Observable, from, throwError } from "rxjs";
import { HttpConfig, getHttpClient } from "./HttpClient";
import { catchError, map } from "rxjs/operators";
import { DcbCustomTranslationsType, DcbTranslations } from "interfaces/Translations";
import { DEFAULT_LANGUAGE } from "services/translations/AteLocalize";

export const getStaticTranslation = (language: string): Observable<DcbTranslations> => {
    return from(axios.get(`https://assets.dcbstatic.com/i18n/latest/${language}.json`))
        .pipe(
            map(({ data }) => {
                return {
                    [language]: data
                }
            }),
            catchError((err) => {
                // if the default language is also not found, it issues an error
                if (language === DEFAULT_LANGUAGE) {
                    return throwError(() => new Error(err));
                }

                const splitLanguage = language.split('-');
                const fallbackLanguage = splitLanguage.length > 1 ? splitLanguage[0] : DEFAULT_LANGUAGE;

                return getStaticTranslation(fallbackLanguage);
            })
        );
}

export const getCustomTranslation = (authConfig: HttpConfig, language: string): Observable<DcbCustomTranslationsType> => {
    return from(getHttpClient(authConfig).get(`/manage/v1/site/translations?lang_code=${language}&lang=${language}`))
        .pipe(
            map(({ data }) => data.data)
        );
}
