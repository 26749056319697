import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISession } from 'interfaces/Session';

export const sessionInitialState: ISession ={
    id: 0,
    dateformat_locale: '',
    language: 'en',
    timezone: ''
};

const slice = createSlice({
    name: 'session',
    initialState: sessionInitialState,
    reducers: {
        updateSession: (state, action: PayloadAction<ISession>) => {
            return action.payload;
        }
    },
});

export const { updateSession } = slice.actions;
export default slice.reducer;
