import { IWidgetTranslationLabelSettings } from 'interfaces/commons.interface';

export interface IAteMyCoursesAndLpWidget {
    UUID: string;
    title?: IWidgetTranslationLabelSettings,
    description?: IWidgetTranslationLabelSettings,
    course_type: MyCoursesAndLpCourseTypeEnum,
    course_status: MyCoursesAndLpCourseStatusEnum,
    hide_courses_in_lp: boolean,
    view: MyCoursesAndLpViewEnum,
    /** Used only in FE */
    isDashboardPage?: boolean,
    /** Used only in FE */
    hideFiltersLabel?: boolean;
    filtersSettings?: IMyCourseAndLpFiltersSettings;
}

export interface IMyCourseAndLpFiltersSettings {
    filters?: string[];
    sort?: string;
    search?: string;
}

export enum MyCoursesAndLpViewEnum {
    GRID = 'grid',
    LIST = 'list',
}

export enum MyCoursesAndLpCourseTypeEnum {
    COURSE = 'course',
    LEARNING_PLAN = 'learning_plan',
    ALL = 'all'
}

export enum MyCoursesAndLpCourseStatusEnum {
    NOT_STARTED = 'not_started',
    IN_PROGRESS = 'in_progress',
    COMPLETED = 'completed',
    ALL = 'all'
}
