import React from 'react';
import './AteBlankSlate.scss';
import { IAteBlankSlate } from 'components/shared/blankSlate/interfaces/AteBlankSlate.interfaces';
import { useSelector } from 'react-redux';
import { getWidgetTranslatedLabel } from 'helpers/translations/translations.helpers';
import { IllustrationSizeEnum } from 'components/shared/illustration/helpers/illustration-size.helper';
import { AteIllustration } from 'components/shared/illustration/AteIllustration';
import { getAppCurrentLanguage } from 'store/translations/translations.selector';
import { getDcbLmsDefaultLanguage } from 'store/bootstrap/boostrap.selector';
import { StyledText, Flex } from '@fluentui/react-migration-v0-v9';

export function AteBlankSlate(props: IAteBlankSlate) {
    const dcbLmsDefaultPlatformLanguage = useSelector(getDcbLmsDefaultLanguage);
    const userLanguage = useSelector(getAppCurrentLanguage);

    return (
        <Flex
            column
            hAlign={'center'}
            className={'ate-blank-slate'}>
            <AteIllustration
                size={IllustrationSizeEnum.MD}
                name={props.illustrationName}
            />
            <StyledText
                className={'title'}
                size={'large'}
                weight={'bold'}
            >
                {getWidgetTranslatedLabel(props.title, userLanguage, dcbLmsDefaultPlatformLanguage)}
            </StyledText>
            <StyledText
                size={'medium'}
                align={'center'}
            >
                {getWidgetTranslatedLabel(props.subtitle, userLanguage, dcbLmsDefaultPlatformLanguage)}
            </StyledText>
        </Flex>
    );
}
