import { IBootstrap } from 'interfaces/Bootstrap';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const bootstrapInitialState: IBootstrap = {
    is_logged_in: false,
    default_platform_language: 'en',
    active_languages: undefined,
    settings: {},
    tc_id: 0,
    policy_id: 0
};

const slice = createSlice({
    name: 'bootstrap',
    initialState: bootstrapInitialState,
    reducers: {
        updateBootstrap: (state, action: PayloadAction<IBootstrap>) => {
            return action.payload
        },
    },
});

export const { updateBootstrap } = slice.actions;
export default slice.reducer;
